
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function DialogRT () {
    return _createElement('div', { 'className': 'dialog-container' }, _createElement('div', {
        'className': 'close',
        'data-cm-role': 'close'
    }, _createElement('svg', {
        'className': 'cm_icon cm_icon-times',
        'height': '20px',
        'role': 'image',
        'viewBox': '0 0 22 22'
    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))), this.name === 'facet-dialog' ? _createElement('div', {
        'className': 'dialog-content',
        'key': '208'
    }, _createElement('div', { 'className': 'dialog-title' }, 'Filter Options'), [this.FacetPanel(function () {
            return _createElement('div', { 'className': 'cm_facet-panel__full-width dialog-body cm_FacetPanel' }, [[this.filterChips(function () {
                        function repeatChips1(chips, chipsIndex) {
                            return [chips(function () {
                                    function repeatI1_reviewChip(i, iIndex) {
                                        return _createElement('span', {
                                            'key': `${ this.field }|${ i }`,
                                            'className': 'cm_star cm_star__' + (!(this.value - i) || this.value - i < 0.25 ? 'empty' : this.value - i < 0.75 ? 'half' : 'full')
                                        });
                                    }
                                    function scopeDollarInchMm1_rangeChip() {
                                        var dollar = [
                                            'price',
                                            'Price'
                                        ].includes(this.field) ? '$' : '';
                                        var inch = [].includes(this.field) ? '"' : '';
                                        var mm = [
                                            'wheel_bore',
                                            'wheel_offset'
                                        ].includes(this.field) ? 'mm' : '';
                                        return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '484' }, dollar, this.selectedRange[0], inch, mm, ' to\n    ', dollar, this.selectedRange[1], inch, mm) : null, this.toPosInf ? _createElement(_Fragment, { 'key': '630' }, dollar, this.selectedRange[0], inch, mm, ' and more') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '738' }, dollar, this.selectedRange[1], inch, mm, ' and less') : null), _createElement('div', { 'className': 'facetentryval' }, _createElement('span', { 'className': 'icon-cancel' })));
                                    }
                                    return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, _createElement('span', { 'className': 'icon-cancel' }))) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement.apply(this, [
                                        'a',
                                        {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        },
                                        _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '),
                                        _map([
                                            0,
                                            1,
                                            2,
                                            3,
                                            4
                                        ], repeatI1_reviewChip.bind(this)),
                                        this.value !== '5' ? _createElement('span', { 'key': '391' }, ' and up') : null
                                    ]), _createElement('div', { 'className': 'facetentryval' }, _createElement('span', { 'className': 'icon-cancel' }))) : this.template === 'rangeChip' ? scopeDollarInchMm1_rangeChip.apply(this, []) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                            'className': 'facettitle',
                            'tabIndex': '0'
                        }, 'Current search:'), _createElement('div', {
                            'className': 'facetbody',
                            'role': 'list'
                        }, _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_chips' },
                            _map(this.chips, repeatChips1.bind(this))
                        ]), _createElement('div', {
                            'className': 'cm_btn  cm_filter-chips_button',
                            'data-cm-role': 'start-over'
                        }, '\n        Start over\n      '))));
                    }, {
                        widgetName: 'filter-chips',
                        items: undefined
                    })]], [this.facets(function () {
                    function repeatFacets1(facets, facetsIndex) {
                        return [facets(function () {
                                function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues2(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatShowAlwaysValues3(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            function onClick1(e) {
                                                {
                                                    if (this.isSelected) {
                                                        e.preventDefault();
                                                    }
                                                }
                                            }
                                            return _createElement('div', {
                                                'className': 'facetdiv ' + this.facetDivClass,
                                                'onClick': onClick1.bind(this)
                                            }, _createElement('input', {
                                                'type': 'radio',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues4(Values, ValuesIndex) {
                                    return [Values(function () {
                                            function onClick1(e) {
                                                {
                                                    if (this.isSelected) {
                                                        e.preventDefault();
                                                    }
                                                }
                                            }
                                            return _createElement('div', {
                                                'className': 'facetdiv ' + this.facetDivClass,
                                                'onClick': onClick1.bind(this)
                                            }, _createElement('input', {
                                                'type': 'radio',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues5(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), !this.isCustomRange ? [
                                                _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem',
                                                    'key': '1541'
                                                }, _createElement('span', {}, this.value)),
                                                _createElement('div', {
                                                    'className': 'facetentryval',
                                                    'key': '1543'
                                                }, this.hitCount)
                                            ] : null, this.isCustomRange ? [_createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem',
                                                    'key': '3901'
                                                }, this.finite ? _createElement('span', { 'key': '503' }, '$', this.range[0], ' - $', this.range[1]) : null, this.toPosInf ? _createElement('span', { 'key': '592' }, '$', this.range[0], ' and more') : null, this.toNegInf ? _createElement('span', { 'key': '668' }, '$', this.range[1], ' and less') : null)] : null);
                                        }, { count: undefined })];
                                }
                                return _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                                    'className': 'facettitle',
                                    'data-cm-role': 'toggle-facet',
                                    'tabIndex': '0'
                                }, _createElement('span', {}, this.name), this.isCollapsed ? [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-angle left',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '39 30 565 565',
                                        'aria-hidden': 'true',
                                        'key': '1410'
                                    }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-angle down',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '39 30 565 565',
                                        'aria-hidden': 'true',
                                        'key': '4620'
                                    }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null), _createElement('div', {
                                    'className': 'facetbody',
                                    'role': 'list'
                                }, this.template === 'simpleFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_simpleFacet',
                                    'key': '837'
                                }, this.showFilterInput ? _createElement('div', {
                                    'className': 'filter-input',
                                    'key': '923'
                                }, [this.filterInput(function () {
                                        return _createElement('div', {
                                            'className': 'input  cm_filterInput',
                                            'placeholder': 'Enter'
                                        });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })], this.inputNotEmpty ? _createElement('span', {
                                    'className': 'filter-input_clear-container',
                                    'onClick': this.clearInput,
                                    'key': '1243'
                                }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                        'className': 'facetdiv',
                                        'key': '14121'
                                    }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_ShowAlwaysValues' },
                                    _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                ]), _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_Values' },
                                    _map(this.Values, repeatValues2.bind(this))
                                ]), this.needShowMore ? _createElement('div', {
                                    'className': 'facetdiv cm_show-all-container',
                                    'key': '3243'
                                }, _createElement('a', {
                                    'className': 'cm_show-all',
                                    'data-cm-role': 'toggle-show-more',
                                    'tabIndex': '0'
                                }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'simpleRadioFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_simpleRadioFacet',
                                    'key': '3569'
                                }, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_ShowAlwaysValues' },
                                    _map(this.ShowAlwaysValues, repeatShowAlwaysValues3.bind(this))
                                ]), _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_Values' },
                                    _map(this.Values, repeatValues4.bind(this))
                                ]), _createElement('div', { 'className': 'facetdiv cm_show-all-container' }, this.needShowMore ? _createElement('a', {
                                    'className': 'cm_show-all',
                                    'data-cm-role': 'toggle-show-more',
                                    'key': '5534'
                                }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null) : null)) : null, this.template === 'priceFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_priceFacet',
                                    'key': '5782'
                                }, !this.ranges.length ? [
                                    _createElement('div', { 'key': '58661' }, '$', this.selectedRange[0], ' to $', this.selectedRange[1]),
                                    '\n  ',
                                    this.slider,
                                    '\n  ',
                                    _createElement('div', {
                                        'className': 'cm_flex',
                                        'key': '58663'
                                    }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: $', this.min), _createElement('div', {}, 'Max: $', this.max))
                                ] : null, this.ranges.length ? [_createElement.apply(this, [
                                        'div',
                                        {
                                            'className': 'cmRepeater_Values',
                                            'key': '61421'
                                        },
                                        _map(this.Values, repeatValues5.bind(this))
                                    ])] : null, [this.Inputs(function () {
                                        return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                                return _createElement('div', {
                                                    'placeholder': 'Min price',
                                                    'className': 'cm_inputMin'
                                                });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                                return _createElement('div', {
                                                    'placeholder': 'Max price',
                                                    'className': 'cm_inputMax'
                                                });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], _createElement('button', {
                                            'type': 'button',
                                            'className': 'cm_btn',
                                            'onClick': this.setCustomRange
                                        }, '\n    GO\n  '));
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]) : null));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_facets' },
                        _map(this.facets, repeatFacets1.bind(this))
                    ]));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], _createElement('div', { 'className': 'dialog-footer' }, _createElement('div', { 'className': 'dialog-footer-content' }, [this.showResultsButton(function () {
            return _createElement('button', {
                'type': 'button',
                'disabled': !this.totalHits,
                'className': 'btn btn-default  cm_showResultsButton'
            }, this.totalHits ? [
                'Show ',
                this.totalHits,
                ' Results'
            ] : null, !this.totalHits ? ['No Results'] : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]))) : null);
}
        export const componentNames = ["cm:filterChips","cm:filterInput","cm:inputMin","cm:inputMax","cm:Inputs","cm:facets","cm:FacetPanel","cm:showResultsButton"]