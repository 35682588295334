//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-676:_5512,_6232,_2768,_7388,_7832,_5988,_7803,_5140;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-676')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-676', "_5512,_6232,_2768,_7388,_7832,_5988,_7803,_5140");
        }
      }catch (ex) {
        console.error(ex);
      }